<template>
  <OrgSignInCard />
</template>

<script setup lang="ts">
import { useUserStore } from "@/store/user";
import { storeToRefs } from "pinia";
const userStore = useUserStore();
const { tenant, dupEmail } = storeToRefs(userStore);
const route = useRoute();
onMounted(async () => {
	// This is to ensure the sign in process doesn't get stuck on step 2 (dupe email) if page is refreshed (as a result of local storage).
	dupEmail.value = false;
	if (
		process.client &&
		(!tenant.value || !tenant.value.id || tenant.value.id === null)
	) {
		if (
			window.location.origin === "https://myunitedmedicareadvisors.com" ||
			window.location.origin === "https://www.myunitedmedicareadvisors.com" ||
			route.query.uma === "true"
		) {
			await userStore.fetchTenant("unitedmedicareadvisors");
		} else {
			await userStore.fetchTenant("smartmatchinsurancesolutions");
		}
	}
});
definePageMeta({
	layout: "no-auth",
});
</script>
